import * as React from "react"
import Header from "../landing/header"
import TestOur from "./TestOur"
import Demo from "./Demo"
import WouldYouLikeToKnow from "./WouldYouLikeToKnow"
import { checkFormat } from "../../common/checkFormat"

const Footer = React.lazy(() => import("../landing/footer"))
const BackToTopButton = React.lazy(() => import("../landing/backToTopButton"))

const Page2dArSolution = ({lang}) => {
  const [loader, setLoader] = React.useState(true)
  const [currId, setCurrId] = React.useState("")

  React.useEffect(() => {
    if (window) {
      checkFormat().then(result => {
        window.localStorage.setItem("images-extension", result)
        setCurrId(window.location.hash)
        setLoader(false)
      })
    }
  }, [loader])


  return (
     <>
     {!loader ? (
       <>
         <div id="top" />
          <Header lang={lang} />
          <div className="content">
            <TestOur lang={lang} />
            <Demo lang={lang} />
            <WouldYouLikeToKnow lang={lang} />
            <Footer lang={lang} />
            <BackToTopButton lang={lang} />
          </div>
       </>
     ) : (
       ""
     )}
   </>
  )
}

export default Page2dArSolution
