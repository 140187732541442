import React from "react"
import Image from "../../common/image"
import { t } from "../../utils/translationUtils"
import { languages } from "../../const/languages"

const TestOur = ({ lang = languages.EN }) => {
  const [text, setText] = React.useState();
  React.useEffect(() => {
    setText(() => {return require(`../../translations/demo/${lang}.json`).page2d.testOur})
  }, [])

  return (
    text &&
    <div 
        className="card services-card test-our"
        style={{ backgroundColor: "#F8F8F8", marginTop: "100px" }}
    >
      <div className="container">
        <div className="grid-template-2-columns">
          <div>
            <h1 className="light">
              {t(text.title)}
            </h1>
            <h5 className="light">
              {t(text.subtitle)}
            </h5>
          </div>
          <Image name="glasses" width={596} height={350} alt="glasses" />
        </div>
      </div>
    </div>
  )
}

export default TestOur
