import React from "react"
import Button from "../../common/button/button"
import { imageBackgroundStyle } from "../../const/image"
import { t } from "../../utils/translationUtils"
import { languages } from "../../const/languages"
import { navigateLink } from "../../common/navigateLink"

const WouldYouLikeToKnow = ({ lang = languages.EN }) => {
  const [text, setText] = React.useState();
  React.useEffect(() => {
    setText(() => {return require(`../../translations/demo/${lang}.json`).page2d.WouldYouLikeToKnow})
  }, [])

  return (
    text &&
    <div className="card contact-web-card">
      <div className="container" style={imageBackgroundStyle("web-app-card-gradient")}>
        <div className="contact-web-header">
          <h2 className="light">
            {t(text.title)} 
          </h2>
          <h3 className="light">
            {t(text.subtitle)} 
          </h3>
        </div>
        <div className="grid-template-2-columns">
          <Button
            className="button-light"
            text={t(text.button1)}
            onClick={() => navigateLink("/vto", lang)}
          />
          <Button
            className="button-light"
            text={t(text.button2)}
            onClick={() => window.open("https://calendly.com/suzie-krawczyk")}
          />
        </div>
      </div>
    </div>
  )
}

export default WouldYouLikeToKnow
